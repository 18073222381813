<template>
  <AuthTemplate>
    <LoginFormVersion slot="body" tracking="login" />
  </AuthTemplate>
</template>

<script>
import global from '@itbpbg/lms-components/src/mixins/global';
import AuthTemplate from '../components/AuthTemplate';
import LoginFormVersion from '../components/Profile/Login/LoginFormVersion';

export default {
  components: {
    AuthTemplate,
    LoginFormVersion,
  },
  mixins: [global],
  beforeRouteLeave(to, from, next) {
    this.$store.commit('auth/setLoginAlert', false);
    next();
  },
  middleware({ store, redirect }) {
    // Check if user just signed up with LinkedIn and do not redirect to lms immediately, but set cookie in created
    if (store.state.auth.user) {
      redirect('/');
    }
  },
  props: {
    type: { type: String, default: '' },
    formTypeProp: { type: String, default: 'login' },
    placesLeft: { type: Number, default: 0 },
  },
  async asyncData({ $axios, route, $config }) {
    const page = await $axios.$get(`/pages/${route.fullPath}`).catch(() => ({}));
    page.fullPath = `${$config.siteUrl}${route.fullPath}`;

    return { page };
  },
  data() {
    return {
      confirmation: false,
      firstLogin: false,
      formType:
        this.$route.name === 'login' || this.$route.name === 'register'
          ? this.$route.name
          : this.formTypeProp,
      login: {
        email: '',
        password: '',
      },
      signup: {
        email: '',
        name: '',
        termsAgreed: false,
      },
      termsError: false,
      url: '',
      keepMeLoggedIn: false,
    };
  },
  head() {
    const linkedData = this.$linkedDataLoad([
      { key: 'WebSite' },
      { key: 'WebPage', values: this.page },
    ]);

    return {
      titleTemplate: `${this.page.metaTitle}%s`,
      link: [
        { rel: 'canonical', href: this.canonicalURL() },
      ],
      meta: this.metaProps(this.page),
      script: [linkedData],
    };
  },
  computed: {
    isRedirected() {
      return !!this.$route.query.redirectUrl;
    },
  },
  async created() {
    this.$root.$on('check-terms', () => {
      this.checkTerms();
    });

    if (process.client) {
      if (this.$store.state.auth.user) {
        this.$gtag.set({
          referrer: this.$config.siteUrl,
        });

        this.$redirectAfterLogin('/my-learning-path/');
      }
    }
  },
  async mounted() {
    this.url = this.$route.params.url;
  },
  methods: {
    checkTerms() {
      if (!this.signup.termsAgreed) {
        this.termsError = true;
        return this.termsError;
      }
      this.termsError = false;
      return this.termsError;
    },
    closeConfirmation() {
      if (this.type === 'modal') {
        this.$root.$emit('close-modal');
      } else {
        this.confirmation = false;
        this.firstLogin = false;
        this.formType = 'login';
        this.signup = {
          email: '',
          name: '',
          termsAgreed: false,
        };
        this.login.password = '';
      }
    },
  },
};
</script>
